.Field {
    position: relative;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
  }
  
  .FieldError {
    position: absolute;
    bottom: -6px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  button {
    padding: 12px;
    width: 240px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: darkblue;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 24px;
  }
  
  button:disabled,
  button[disabled] {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  
  .LoginForm {
    width: 645px;
    background: #FFF;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.30);
    border-radius: 30px;
  }

/* Enhanced shake animation for error feedback */
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
}

/* Animation with no border styling for inputs */
.input-shake-animation {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

/* Original shake animation for other elements */
.shake-animation {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  /* No additional border styles here since they're applied via the styled component */
}

/* Add tactile feedback through border patterns that don't rely on color */


/* For screen readers */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
