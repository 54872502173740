/* PublicLayout.css */
html, body, #root {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.public-layout-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.public-layout-content {
  width: 100%;
  overflow-y: auto;

} 