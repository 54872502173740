.flip-container {
    perspective: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 800px; /* Adjust width as needed */
    height: 580px; /* Adjust height as needed */
    margin: auto;
    
  }
  
  .flipper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .flipper.flipped {
    transform: rotateY(180deg);
  }
  


.front {
    font-size: 1em; /* Larger text on the front */
}


  
.paper-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start; /* Align content to the start horizontally */
    align-items: flex-start; /* Align content to the start vertically */
    text-align: left; /* Align text to the left */
}

@keyframes bounce-shadow-wiggle {
  0%, 100% {
    transform: translateY(-1px) rotate(-4deg);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: translateY(2px) rotate(5deg);
    box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.2);
  }
}

.wiggle {
  animation: bounce-shadow-wiggle 0.15s ease-in-out 3;
}

/* Card counter animation when total changes */
@keyframes counterPulse {
  0% { 
    transform: scale(1);
    opacity: 0.7;
  }
  50% { 
    transform: scale(1.35);
    opacity: 1;
  }
  100% { 
    transform: scale(1);
    opacity: 0.7;
  }
}

.card-counter-animate {
  animation: counterPulse 0.7s ease-out;
  border-radius: 15px;
  padding: 2px 10px;
}




  