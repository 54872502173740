/* Apply default gradient background to html to prevent white flash during loading */
html {
  
  background-attachment: fixed;
  min-height: 100%;
}

body {
  color: var(--primary-color);
  margin: 0;
  padding: 0;
  font-family: 'Nunito, sans-serif';
  overflow: hidden;
  /* Background set by ThemeContext will override this */
  background: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  overscroll-behavior: none; /* Prevent overscroll altogether */
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

