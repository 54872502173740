@keyframes drawerSlideOpen {
  0% { width: 72px; }
  70% { width: 245px; } /* Reduced overshoot from 250px to 245px */
  85% { width: 235px; } /* Reduced undershoot from 232px to 235px */
  100% { width: 240px; } /* Final size */
}

@keyframes drawerSlideClose {
  0% { width: 240px; }
  70% { width: 68px; } /* Reduced overshoot from 65px to 68px */
  85% { width: 74px; } /* Reduced undershoot from 75px to 74px */
  100% { width: 72px; } /* Final size */
}

@keyframes mainContentBounceOpen {
  0% { transform: translateX(0); }
  60% { transform: translateX(6px); } /* Slight overshoot */
  80% { transform: translateX(-3px); } /* Slight undershoot */
  100% { transform: translateX(0); } /* Back to normal */
}

@keyframes mainContentBounceClose {
  0% { transform: translateX(0); }
  60% { transform: translateX(-5px); } /* Slight overshoot */
  80% { transform: translateX(2px); } /* Slight undershoot */
  100% { transform: translateX(0); } /* Back to normal */
}

/* Main content area transitions with bounce when drawer opens */
.main-content-slide {
  transition: 
    width 500ms cubic-bezier(0.34, 1.5, 0.64, 1),
    margin-left 500ms cubic-bezier(0.34, 1.5, 0.64, 1);
}

/* Drawer opening bounce animation for main content */
.main-content-bounce-open {
  animation: mainContentBounceOpen 1000ms forwards;
}

/* Drawer closing bounce animation for main content */
.main-content-bounce-close {
  animation: mainContentBounceClose 1000ms forwards;
}

/* Icon button bounce animation */
.drawer-toggle-button {
  box-shadow: none !important; /* Ensure no shadow appears */
  background-color: transparent !important; /* Ensure no background appears */
}

.drawer-toggle-button:active {
  animation: buttonPress 300ms forwards;
  background-color: transparent !important; /* Maintain transparency during press */
  box-shadow: none !important; /* Explicitly remove shadow on press */
}

@keyframes buttonPress {
  0% { transform: scale(1); }
  50% { transform: scale(0.85); }
  100% { transform: scale(1); }
}

/* Disable transitions until the animation class is applied */
.no-transition {
  transition: none !important;
  animation: none !important;
}

/* Simplified Panic mode animations */
@keyframes panicShake {
  0% { transform: translate(0, 0); }
  25% { transform: translate(-5px, 0); }
  50% { transform: translate(0, 0); }
  75% { transform: translate(5px, 0); }
  100% { transform: translate(0, 0); }
}

/* Apply this class to body during panic mode */
.panic-shake {
  animation: panicShake 0.5s infinite;
} 