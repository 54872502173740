/* TosPage.css */
html, body, #root {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

/* Ensure the content is scrollable */
.tos-content {
  height: auto;
  min-height: 100vh;
  overflow-y: auto;
}

/* Add some spacing for better readability */
.tos-container {
  padding-bottom: 2rem;
}
