.flip-container {
  perspective: 1200px;
  perspective-origin: center center;
  margin: 0 auto; /* center horizontally */
  position: relative;
  display: flex;         /* or inline-flex */
  justify-content: center;
  align-items: center;
}

  
.flipper {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  transform-origin: center center; /* added */
}
  
  .flipper.flipped {
    transform: rotateY(180deg);
  }
  
  .front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

.front {
    font-size: 3em; /* Larger text on the front */
}

.back {
    transform: rotateY(180deg);
    font-size: 1.2em; /* Slightly smaller text on the back */
}

  
  .paper-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  
  }

  @keyframes fadeInSparkle {
    0% {
        opacity: 0;
        transform: translateY(20px);
        background-position: left;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
        background-position: right;
    }
}

.sparkly-text {
    display: inline-block;
    background-image: linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(255,255,255,0.8) 75%, rgba(255,255,255,0) 100%);
    background-size: 200% 100%;
    animation: fadeInSparkle 1.5s ease-out forwards;
    color: #d6b317; /* or any preferred color */
}

@keyframes bounce-shadow-wiggle {
  0%, 100% {
    transform: translateY(-1px) rotate(-4deg);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: translateY(2px) rotate(5deg);
    box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.2);
  }
}

.wiggle {
  animation: bounce-shadow-wiggle 0.15s ease-in-out 3;
}



@keyframes hop {
  0% { transform: translateY(0); }
  20% { transform: translateY(-20px); }
  40% { transform: translateY(0); }
  60% { transform: translateY(-10px); }
  80% { transform: translateY(0); }
  100% { transform: translateY(0); }
}

.hop-animation {
  animation: hop 1s ease-in-out forwards;
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-15deg); }
  20% { transform: rotate(15deg); }
  30% { transform: rotate(-10deg); }
  40% { transform: rotate(10deg); }
  50% { transform: rotate(-5deg); }
  60% { transform: rotate(5deg); }
  70% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

.wiggle-animation {
  animation: wiggle 0.8s ease-in-out forwards;
}


@keyframes melt {
  0% { transform: scaleY(1); opacity: 1; }
  100% { transform: scaleY(0); opacity: 0; }
}

.melt-animation {
  animation: melt 1s ease-in-out forwards;
  transform-origin: bottom;
}

/* Simple bounce animation for the corner smiley */
@keyframes popBounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.corner-smiley-pop {
  animation: popBounce 0.5s ease-out;
}

/* Card counter animation when total changes */
@keyframes counterPulse {
  0% { 
    transform: scale(1);
    opacity: 0.7;
  }
  50% { 
    transform: scale(1.35);
    opacity: 1;
  }
  100% { 
    transform: scale(1);
    opacity: 0.7;
  }
}

.card-counter-animate {
  animation: counterPulse 0.7s ease-out;
  border-radius: 15px;
  padding: 2px 10px;
}
